<template>
  <div id="mainview">
    <div id="container"></div>

    <o2vr :obj="obj" />
    <Play :floor="obj.floor_name" :wall="obj.wall_name" :speed="6000" />
    <Function :obj="obj" :vip="vip" />

    <Login1 v-if="id == 4040 || id == 4095 || id == 4062 || id == 4179 || id == 3989" />
    <Login :flag1="obj.multi" :room_id="id" v-else />

    <client />
  </div>
</template>

<script>
import o2vr from "../components/banner.vue";
import { getScene, get_scene_user, add_popular } from "../assets/api/index";
import QS from "qs";
import Play from "../../showroom/components/playNew_repair.vue";
import Function from "./function.vue";
import Login from "../components/login.vue";
import Login1 from "../components/login1.vue";
import axios from "axios";
import client from "./client.vue";

export default {
  name: "MainView",
  components: {
    o2vr,
    Play,
    Function,
    Login,
    Login1,
    client,
  },
  data() {
    return {
      desc: "3D View",
      O2: {},
      selObj: {},
      url: "",
      obj: {},
      id: "",
      flag: false,
      vip: "",
    };
  },
  methods: {
    readTextFile(file, callback) {
      var rawFile = new XMLHttpRequest();
      rawFile.overrideMimeType("application/json");
      rawFile.open("GET", file, true);
      rawFile.onreadystatechange = function () {
        if (rawFile.readyState === 4 && rawFile.status == "200") {
          callback(rawFile.responseText);
        }
      };
      rawFile.send(null);
    },
    load(res) {
      document.showroom.load(
        Object.assign(JSON.parse(res.data.edit_info), { url: res.data.url })
      );
      this.obj = document.showroom.room;
      if (document.showroom.room.sceneTitle) {
        var title = document.showroom.utf8to16(
          document.showroom.decode64(document.showroom.room.sceneTitle)
        );
        document.title = title;
      } else {
        document.title = "三维编辑器";
      }
      document.o2.set_edit_mode(false);
    },
    changeSence() {
      var that = this;

      if (document.o2.type == "local") {
        this.readTextFile("public/packge.json", function (text) {
          var data = JSON.parse(text);
          console.log(data);
          var id = data.id;
          that.id = id;
          document.showroom.room_id = id;
          var secret = data.secret;
          console.log(id, window.atob(secret));
          if (id == window.atob(secret)) {
            get_scene_user({ id }).then((res) => {
              console.log(res);
              var guid = res.user.guid;
              document.showroom.guid = guid;
              axios
                .get("https://api.mvpmeta.com/api/user/public/vip", {
                  params: { guid },
                })
                .then((res) => {
                  document.showroom.vip = res.data.data.vip;
                  that.vip = res.data.data.vip;
                });
            });

            getScene({ id }).then((res) => {
              if (JSON.parse(res.data.edit_info).pswFlag) {
                var psw = JSON.parse(res.data.edit_info).psw;

                that
                  .$prompt("请输入访问密码", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    inputErrorMessage: "邮箱格式不正确",
                  })
                  .then(({ value }) => {
                    if (value == psw) {
                      that.$message({
                        type: "success",
                        message: "验证成功,即将进入场景 ",
                      });
                      that.load(res);
                    } else {
                      that.$message({
                        type: "info",
                        message: "密码错误,您没有权限访问",
                      });
                    }
                  })
                  .catch(() => {
                    that.$message({
                      type: "info",
                      message: "取消输入",
                    });
                  });
              } else {
                that.load(res);
              }
            });
          } else {
            that.$notify({
              title: "警告",
              message: "您暂无权限浏览",
              type: "warning",
            });
          }
        });
      } else {
        const query = QS.parse(location.hash.substring(3));
        var id = query.id;
        this.id = id;

        var ratio = query.ratio;
        document.showroom.room_id = id;
        var secret = query.secret;
        add_popular({ id: this.id });
        if (id == window.atob(secret)) {
          get_scene_user({ id }).then((res) => {
            console.log(res);
            var guid = res.user.guid;
            document.showroom.guid = guid;
            axios
              .get("https://api.mvpmeta.com/api/user/public/vip", { params: { guid } })
              .then((res) => {
                document.showroom.vip = res.data.data.vip;
                this.vip = res.data.data.vip;
                this.flag = true;
              });
          });

          getScene({ id, reg_preview: ratio }).then((res) => {
            if (JSON.parse(res.data.edit_info).pswFlag) {
              var psw = JSON.parse(res.data.edit_info).psw;

              this.$prompt("请输入访问密码", "提示", {
                confirmButtonText: "确定",
                inputErrorMessage: "邮箱格式不正确",
              })
                .then(({ value }) => {
                  if (value == psw) {
                    this.$message({
                      type: "success",
                      message: "验证成功,即将进入场景 ",
                    });
                    this.load(res);
                  } else {
                    this.$message({
                      type: "info",
                      message: "密码错误,您没有权限访问",
                    });
                  }
                })
                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "取消输入",
                  });
                });
            } else {
              this.load(res);
            }
          });
        } else {
          this.$notify({
            title: "警告",
            message: "您暂无权限浏览",
            type: "warning",
          });
        }
      }
    },
  },
  beforeCreate() {},
  created() {
    this.changeSence();
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
#mainview,
#container {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.el-main {
  padding: 0 !important;
  background-color: #e9eef3;
  color: #333;
  height: 100%;
}
.inner {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000000;
  .banner {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }
  .bot {
    position: absolute;
    bottom: 18%;
    left: 0;
    right: 0;
    margin: auto;
    width: 200px;
    cursor: pointer;
  }
  .bot:hover {
    transition: 0.5s;
    transform: scale(1.2);
  }
}
.shadow {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba($color: #000000, $alpha: 0.5);
  z-index: 999;
}
</style>
