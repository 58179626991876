
import { LUTCubeLoader } from 'three/examples/jsm/loaders/LUTCubeLoader.js';

import * as THREE from 'three/build/three.module';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls';
import {
    gen_oss_url, get_solo_media
} from "../../showroom/assets/api/index";
import Bus from "./bus"
class _area_hot_trigger {
    constructor() {
        this.enter_type = 0;//进入触发 0=无 1=触发一个展项 2=关闭展项 3=打开一个动画 4=关闭一个动画
        this.enter_target = "";//触发对象的名称 type==1的时候是showbox的title type==2||type==3的时候是动画的名称 
        this.leave_type = 0;//离开热点区域，同上
        this.leave_target = "";
        this.wts = null;//位置，存储用
        this.type_geo = 0;//0=BOX 1=SPHERE
        this.bInside = false;//临时存储，角色是否在区域内
    }

    to_json = () => {
        let obj = {};
        if (this.enter_type != 0) obj.enter_type = this.enter_type;
        if (this.enter_target != "") obj.enter_target = document.showroom.encode64(document.showroom.utf16to8(this.enter_target));
        if (this.leave_type != 0) obj.leave_type = this.leave_type;
        if (this.leave_target != "") obj.leave_target = document.showroom.encode64(document.showroom.utf16to8(this.leave_target));
        if (this.type_geo != 0) obj.type_geo = this.type_geo;
        return obj;
    }
    from_json = (json_obj) => {
        if (json_obj.enter_type) this.enter_type = json_obj.enter_type;
        if (json_obj.leave_type) this.leave_type = json_obj.leave_type;
        if (json_obj.enter_target) this.enter_target = document.showroom.utf8to16(document.showroom.decode64(json_obj.enter_target));
        if (json_obj.leave_target) this.leave_target = document.showroom.utf8to16(document.showroom.decode64(json_obj.leave_target));
        if (json_obj.type_geo) this.type_geo = json_obj.type_geo;
    }
}
class _showbox {
    constructor() {
        this.title = null;//标题
        this.Hyperlinks = null;//超链接
        this.text = null;//文字
        this.res_list = [];
        this.voice_res = null;//音频资源
        this.url = null;
        this.urlPopType = 0;//0=新窗口弹窗 1=iframe嵌入
        this.bPopUp = false;//是否弹出，没有的话触发会直接播放音视频而不弹出信息框
        this.linkFlag = null;
    }

    to_json = () => {

        let obj = {};



        if (this.linkFlag) obj.linkFlag = this.linkFlag;

        if (this.linkValue) obj.linkValue = this.linkValue
        if (this.Hyperlinks) obj.Hyperlinks = this.Hyperlinks;
        if (this.title) obj.title = document.showroom.encode64(document.showroom.utf16to8(this.title));

        console.log(obj.title)
        if (this.text) obj.text = document.showroom.encode64(document.showroom.utf16to8(this.text));
        if (this.voice_res) obj.voiceid = this.voice_res.id;
        if (this.bPopUp) {
            obj.bPopUp = this.bPopUp
        }
        else {
            obj.bPopUp = false
        };


        if (this.urlPopType != 0) obj.b
        if (this.res_list.length) {
            obj.res_list = [];
            for (let i = 0; i < this.res_list.length; i++) obj.res_list.push(this.res_list[i].id);
        }
        return obj;
    }
    collect_res = (sets) => {

        if (this.voice_res) sets.add(this.voice_res.id);
        if (this.res_list.length) {
            for (let i = 0; i < this.res_list.length; i++) sets.add(this.res_list[i].id);
        }
    }
    from_json = (json_obj, sr) => {

        this.res_list = [];
        if (json_obj.Hyperlinks) this.Hyperlinks = json_obj.Hyperlinks;
        if (json_obj.linkFlag) this.linkFlag = json_obj.linkFlag;
        if (json_obj.title) this.title = document.showroom.utf8to16(
            document.showroom.decode64(json_obj.title)
        );
        if (json_obj.text) this.text = document.showroom.utf8to16(
            document.showroom.decode64(json_obj.text)
        );;

        if (json_obj.bPopUp) { this.bPopUp = json_obj.bPopUp } else {
            this.bPopUp = false
        };
        if (json_obj.resid) {
            let res = sr.user_item_info[json_obj.resid.toString()];
            if (res) this.res_list.push(res);
        }
        if (json_obj.voiceid) {
            this.voice_res = sr.user_item_info[json_obj.voiceid.toString()];
        }
        if (json_obj.res_list) {
            for (let i = 0; i < json_obj.res_list.length; i++) {
                let res = sr.user_item_info[json_obj.res_list[i].toString()];
                if (res) this.res_list.push(res);
            }
        }

        if (json_obj.linkValue) {
            this.linkValue = json_obj.linkValue
        }
    }
}
class showroom {
    constructor() {
        this.o2 = null;
        this.room = {

        }
        this.moveFlag = true
        this.hots = [];
        this.models = [];
        this.show_boxes = [];
        this.hot_areas = [];
        this.hot_urls = ["", "", "", "", "", "", "", "", "", ""
            // document.showroom.getUrl("hotpot/6.zip"),
            // document.showroom.getUrl("hotpot/6.zip"),
        ];

    }
    focus = () => {
        document.showroom.moveFlag = false

    }
    blur = () => {
        document.showroom.moveFlag = true
    }
    test_bind_click = (object) => {
        return object.showbox;
    }
    record_guide_view = () => {
        this.room.guide = this.o2.getView();
    }
    get_object_bind = (object) => {
        if (!this.room.bind) return null;
        for (let i = 0; i < this.room.bind.length; i++) {
            let bind = this.room.bind[i];
            if (bind.uuid == object.uuid) return bind;
        }
        return null;
    }
    _collect_obj_showbox = (obj1, sbs) => {
        if (obj1.showbox) {
            sbs.push(obj1);
        }
        for (let i = 0; i < obj1.children.length; i++) {
            let obj = obj1.children[i];
            this._collect_obj_showbox(obj, sbs);
        }
    }
    collect_showboxes = () => {
        let sbs = [];
        for (let i = 0; i < this.o2.scene.children.length; i++) {
            let obj = this.o2.scene.children[i];
            this._collect_obj_showbox(obj, sbs);
        }
        return sbs;
    }
    collect_animations = () => {
        return this.o2.animation.timelines;
    }
    before_save = () => {
        //收集用户资源
        this.room.user_item = [];
        let sets = new Set;
        if (this.room.bind) {
            for (let i = 0; i < this.room.bind.length; i++) {
                sets.add(this.room.bind[i].resid);
            }
        }
        for (let i = 0; i < this.o2.exhibition.objects.length; i++) {
            let object = this.o2.exhibition.objects[i];
            let bind = this.get_object_bind(object);
            if (bind && object.showbox) {
                bind.sb = object.showbox.to_json();
                if (object.bind_id)
                {            
                    sets.add(object.bind_id);
                }
                object.showbox.collect_res(sets);
            }
        }
        this.room.hots = [];
        for (let i = 0; i < this.hots.length; i++) {
            let hot = this.hots[i];
            let obj = {};
            obj.id = hot.hot_idx;
            obj.wts = hot.matrix;
            obj.scale = hot.hot_scale;
            if (hot.showbox) {
                obj.sb = hot.showbox.to_json();
                hot.showbox.collect_res(sets);
            }
            this.room.hots.push(obj);
        }
        if (this.lutName)
        {
            this.room.lutName=this.lutName;
        }
        this.room.hot_areas = [];
        for (let i = 0; i < this.hot_areas.length; i++) {
            let hot = this.hot_areas[i];
            let obj = hot.aht.to_json();
            obj.wts = hot.matrix;
            this.room.hot_areas.push(obj);
        }

        if (this.models) {
            this.room.user_model = [];
            for (let i = 0; i < this.models.length; i++) {
                let model = this.models[i];
                if (model.item) {
                    let mdl_item = {};
                    mdl_item.resid = model.item.id;
                    mdl_item.wts = model.matrix;
                    mdl_item.binds = [];
                    this.collect_model_bind(model, mdl_item.binds, "", sets);
                    this.room.user_model.push(mdl_item);
                    sets.add(model.item.id);
                }
            }
        }
        for (let item of sets.keys()) {
            this.room.user_item.push(item);
        }
        //camera
        this.room.camera_record = null;
        if (this.get_record_json) {
            this.room.camera_record = this.get_record_json();
        }
    }

    collect_model_bind = (model, binds, depth, sets) => {
        if (model.bind_id) {
            let b = {};
            b.depth = depth;
            b.bind_id = model.bind_id;
            sets.add(b.bind_id);
            if (model.showbox) {
                model.showbox.collect_res(sets);
                b.sb = model.showbox.to_json();
            }
            binds.push(b);
        }
        for (let i = 0; i < model.children.length; i++) {
            let depth2 = depth + "_" + i;
            this.collect_model_bind(model.children[i], binds, depth2, sets);
        }
    }

    bind_items = () => {
        if (this.room.bind) {
            for (let i = 0; i < this.room.bind.length; i++) {
                let resid = this.room.bind[i].resid;
                let object_id = this.room.bind[i].uuid;
                let sb = this.room.bind[i].sb;
                let object;

                for (let i = 0; i < this.o2.exhibition.objects.length; i++) {
                    let obj = this.o2.exhibition.objects[i];
                    if (obj.uuid == object_id) { object = obj; break; }
                }
                let res = this.user_item_info[resid.toString()];
                if (object && res) {

                    this.add_exhibition_item(object, res);

                    if (sb)//有保存showbox
                    {

                        object.showbox.from_json(sb, this);
                    }
                }
            }
        }
    }
    get_model_exhibition_item = (model, objects) => {
        if (model.type == "Mesh" && (model.bChangeMap == true || model.material.map == this.o2.map_red || model.bind_id)) {
            objects.push(model);
        }

        for (let i = 0, l = model.children.length; i < l; i++) {
            this.get_model_exhibition_item(model.children[i], objects);
        }
    }

    getUrl = () => {
        this.hot_urls = [
            "https://o2vr.net/hots/6.zip",
            "https://o2vr.net/hots/7.zip",
            "https://o2vr.net/hots/8.zip",
            "https://o2vr.net/hots/9.zip",
            "https://o2vr.net/hots/11.zip",
            "https://o2vr.net/hots/12.zip",
            "https://o2vr.net/hots/13.zip",
            "https://o2vr.net/hots/15.zip",
            "https://o2vr.net/hots/16.zip",
            "https://o2vr.net/hots/17.zip",
        ]
        // list.forEach((e, i) => {
        //     setTimeout(() => {
        //         gen_oss_url({ object: e }).then(res => {
        //             // console.log(this.hot_urls, res)
        //             this.hot_urls[i] = res
        //             // console.log(this.hot_urls)
        //         })
        //     }, 500);
        // })
        // console.log(this.hot_urls);
    }
    get_all_exhibition_items = () => {
        if (!this.o2.exhibition) {
            this.o2.exhibition = {};
        }
        if (!this.o2.exhibition.objects) {
            this.o2.exhibition.objects = [];
        }
        let objects = this.o2.exhibition.objects;
        for (let i = 0; i < this.models.length; i++) {
            this.get_model_exhibition_item(this.models[i], objects);
        }
        return objects;
    }


    add_hot_area = (intersection, item) => {
        let showroom = this;
        let geometry;
        if (item == 0) geometry = new THREE.BoxGeometry(2000, 2000, 2000);
        else geometry = new THREE.SphereGeometry(1000, 12, 12);
        let obj3 = new THREE.Mesh(geometry, new THREE.MeshNormalMaterial({ opacity: 0.5, transparent: true }));
        if (!this.hot_area_parent) {
            this.hot_area_parent = new THREE.Object3D();
            showroom.o2.scene.add(this.hot_area_parent);
        }
        if (obj3) {
            obj3.bHot_area = true;
            obj3.aht = new _area_hot_trigger;
            obj3.aht.type_geo = item;
            showroom.o2.renew_all_uuid(obj3);
            showroom.hot_areas.push(obj3);
            obj3.position.set(intersection.point.x, intersection.point.y, intersection.point.z);
            //移动到物体表面来
            let nor = intersection.face.normal.clone();
            nor.applyEuler(intersection.object.rotation);
            nor.multiplyScalar(100);
            obj3.position.add(nor);
            this.hot_area_parent.add(obj3);
            //添加鼠标工具
            {
                showroom.set_gizmo_object(obj3);
                showroom.set_gizmo_mode(1);
            }
        }
        return obj3;
    }

    add_hot_item = (intersection, item) => {
        let showroom = this;
        let idx = item;
        if (item.name == "手势") idx = 1;
        let url = this.hot_urls[idx];

        let promise = this.o2.import_object_url(url);
        promise.then((obj3) => {
            if (obj3) {
                obj3.hot = true;
                obj3.hot_idx = idx;
                obj3.hot_scale = 1;
                showroom.o2.renew_all_uuid(obj3);
                showroom.hots.push(obj3);
                showroom.o2.scene.add(obj3);
                obj3.position.set(intersection.point.x, intersection.point.y, intersection.point.z);
                //移动到物体表面来
                let nor = intersection.face.normal.clone();
                nor.applyEuler(intersection.object.rotation);
                nor.multiplyScalar(100);
                obj3.position.add(nor);


                obj3.showbox = new _showbox;
                //添加鼠标工具
                {
                    showroom.set_gizmo_object(obj3);
                    showroom.set_gizmo_mode(1);
                }
                if (this.on_add_hot_finish) {
                    this.on_add_hot_finish(obj3)
                }
            }
        })
    }
    scale_hot = (item, scale) => {
        if (item) {
            if (item.hot_scale == null) {
                item.hot_scale = 1;
            }
            let s = scale / item.hot_scale;
            item.hot_scale = scale;
            item.scale.multiplyScalar(s);
            console.log("scale_hot" + s);
        }
    }
    generate_guide_bb = () => {
        if (this.guide_obj) this.o2.scene.remove(this.guide_obj);
        this.guide_obj = new THREE.Object3D;
        this.o2.scene.add(this.guide_obj);
        this.guide_obj.visible = false;
        if (this.room.viewList) {
            for (let i = 0; i < this.room.viewList.length; i++) {
                let viewstr = this.room.viewList[i].view;
                var view = viewstr.split(",");
                let pos = new THREE.Vector3;
                pos.set(parseInt(view[6]), parseInt(view[7]), parseInt(view[8]));
                let canvas = document.createElement("canvas");
                canvas.width = 160;
                canvas.height = 16;
                const drawingContext = canvas.getContext("2d");
                drawingContext.fillStyle = "#000000";
                drawingContext.globalAlpha = 0.8;
                drawingContext.fillRect(0, 0, 160, 16);
                drawingContext.globalAlpha = 1;
                drawingContext.fillStyle = "#ffffff";
                drawingContext.font = "12px Georgia";
                drawingContext.textAlign = "center";
                drawingContext.fillText(this.room.viewList[i].name, 80, 13);
                let map = new THREE.CanvasTexture(canvas);
                let sprite = new THREE.Sprite(
                    new THREE.SpriteMaterial({ map: map, color: "#ffffff" })
                );
                sprite.guide_view = viewstr;
                sprite.position.set(pos.x, pos.y + 3000, pos.z);
                sprite.scale.set(3000, 300, 1);
                sprite.material.depthWrite = false;
                sprite.material.depthTest = false;
                this.guide_obj.add(sprite);
            }
        }
    }
    switch_lut=(name)=>
    {
        if (name=="原始")
        {
            if (this.o2.lutPass) {
                this.o2.lutPass.intensity = 0;
            }
        }else{
            if (this.o2.postEffect.bDefaultRender)
            {
                console.log(this.o2.postEffect);
                this.o2.postEffect.bDefaultRender = false;
                this.o2.postEffect.fxaa = true;
                this.o2.postEffect.lut = true;
                this.o2.postEffect.glow = false;
                this.o2.postEffect.lutFile = "https://o2vr.net/luts/"+name+".cube";
                this.o2.postEffect.lutPower = 1;
                this.o2.create_render_pass();
            }else{
                if (this.o2.lutPass) {
                    this.o2.lutPass.intensity = 1;
                    let o2 = this.o2;
                    if (1) {
                        new LUTCubeLoader()
                            .load("https://o2vr.net/luts/"+name+".cube", function (result) {
                                o2.lutPass.lut = result.texture3D;
                                o2.lutPass.enabled = true;
                            });
                    }
                }
            }
        }
        this.lutName=name;
    }
    prepare_run = () => {
        let all_objects = this.get_all_exhibition_items();
        this.click_able_items = [];
        for (let i = 0; i < all_objects.length; i++) {
            let obj = all_objects[i];
            if (obj.showbox) {
                this.click_able_items.push(obj);
            }
        }

        if (this.room.view) {
            this.o2.toView(this.room.view);
        }
        for (let i = 0; i < this.o2.animation.timelines.length; i++) {
            let tl = this.o2.animation.timelines[i];
            if (tl.name.substr(0, 5) == "_loop") {
                tl.play();
            }
        }
        this.generate_guide_bb();
        if (this.gizmo) {
            this.gizmo.detach();
        }
    }
    set_fbx_mtl = (obj)=>
    {
        if (obj.type=="Mesh"&&obj.material&&obj.material.type=="MeshPhongMaterial")
        {
            obj.material.lightMap=this.o2.map_white;
        }
        for (let i=0;i<obj.children.length;i++)
        {
            this.set_fbx_mtl(obj.children[i]);
        }
    }
    add_model_item = (intersection, item) => {
        console.log(item)
        let ext = item.name.substr(-3, 3);
        console.log(ext);
        ext = ext.toLowerCase();
        let _o2=this.o2;
        let showroom=this;
        if (ext=="fbx")//FBX装载
        {

            // model
            const loader = new FBXLoader();
            loader.load( item.url, function ( object ) {
                console.log(object);
                let obj3=object;
                obj3.user_model = true;
                obj3.item = item;
                showroom.models.push(obj3);
                showroom.set_fbx_mtl(obj3);
                showroom.o2.scene.add(obj3);
                obj3.position.set(intersection.point.x, intersection.point.y, intersection.point.z);
                if (!showroom.user_item_info[item.id.toString()])//插入到userinfo
                {
                    let info = {};
                    info.id = item.id;
                    info.name = item.name;
                    info.preview = item.preview;
                    info.url = item.url;
                    showroom.user_item_info[item.id.toString()] = info;
                }
                //添加鼠标工具
                showroom.set_gizmo_object(obj3);
                showroom.set_gizmo_mode(1);
            } );
            return;
        }

        let promise = this.o2.import_object_url(item.url);
        promise.then((obj3) => {
            if (obj3) {
                obj3.user_model = true;
                obj3.item = item;
                showroom.o2.renew_all_uuid(obj3);
                showroom.models.push(obj3);
                showroom.o2.scene.add(obj3);
                obj3.position.set(intersection.point.x, intersection.point.y, intersection.point.z);
                //判断是不是竖立面，如果是竖立面需要旋转对象角度
                let nor = intersection.face.normal.clone();
                nor.applyEuler(intersection.object.rotation);

                let dir2 = new THREE.Vector3;
                this.o2.camera.getWorldDirection(dir2);
                if (dir2.dot(nor) > 0) nor.multiplyScalar(-1);

                //nor.applyNormalMatrix(intersection.object.matrixWorld);
                let dir_up = new THREE.Vector3(0, 1, 0);
                let dot = nor.dot(dir_up);
                if (Math.abs(dot < 0.5)) {
                    let angle = Math.asin(nor.x);
                    if (nor.z < 0) angle = 3.1415 - angle;
                    obj3.rotation.set(0, angle, 0);
                }
            }

            if (!showroom.user_item_info[item.id.toString()])//插入到userinfo
            {
                let info = {};
                info.id = item.id;
                info.name = item.name;
                info.preview = item.preview;
                info.url = item.url;
                showroom.user_item_info[item.id.toString()] = info;
            }
            //添加鼠标工具
            {
                showroom.set_gizmo_object(obj3);
                showroom.set_gizmo_mode(1);

            }
        })
    }

    change_bind_item_texture = (obj, map) => {
        if (obj.type == "Mesh" && obj.material.map == this.o2.map_red) {
            obj.bChangeMap = true;
            obj.material.map = map;
        }
        for (let i = 0; i < obj.children.length; i++) {
            this.change_bind_item_texture(obj.children[i], map);
        }
    }

    load_hot_areas = () => {
        this.hot_areas = [];
        if (this.hot_area_parent)//之前有，新建一个
        {
            this.o2.scene.children.remove(this.hot_area_parent);
        }


        this.hot_area_parent = new THREE.Object3D();
        this.hot_area_parent.visible = false;
        this.o2.scene.add(this.hot_area_parent);
        let saved = this.room.hot_areas;
        this.room.hot_areas = null;
        if (!saved) return;
        for (let i = 0; i < saved.length; i++) {
            let aht_saved = saved[i];
            let aht = new _area_hot_trigger;
            aht.from_json(aht_saved);
            let geometry;
            if (aht.type_geo == 0) geometry = new THREE.BoxGeometry(2000, 2000, 2000);
            else geometry = new THREE.SphereGeometry(1000, 12, 12);

            let obj3 = new THREE.Mesh(geometry, new THREE.MeshNormalMaterial({ opacity: 0.5, transparent: true }));
            obj3.bHot_area = true;
            obj3.aht = aht;
            let mat = aht_saved.wts;
            obj3.position.set(0, 0, 0);
            obj3.scale.set(1, 1, 1);
            obj3.rotation.set(0, 0, 0);
            obj3.applyMatrix4(mat);
            aht.bInside = false;
            this.hot_areas.push(obj3);
            this.hot_area_parent.add(obj3);
        }
    }

    load_hots = () => {
        this.hots = [];
        let saved = this.room.hots;
        this.room.hots = null;
        let showroom = this;
        if (!saved) return;
        for (let i = 0; i < saved.length; i++) {
            let savedhot = saved[i];
            let url = this.hot_urls[savedhot.id];
            let promise = this.o2.import_object_url(url);
            promise.then((obj3) => {
                if (obj3) {
                    obj3.hot = true;
                    obj3.hot_idx = savedhot.id;
                    obj3.hot_scale = savedhot.scale;
                    console.log("scale:" + savedhot.scale);
                    showroom.o2.renew_all_uuid(obj3);
                    showroom.hots.push(obj3);
                    showroom.o2.scene.add(obj3);
                    let mat = savedhot.wts;
                    obj3.position.set(0, 0, 0);
                    obj3.scale.set(1, 1, 1);
                    obj3.rotation.set(0, 0, 0);
                    obj3.applyMatrix4(mat);
                    if (savedhot.sb) {
                        let showbox = new _showbox;
                        showbox.from_json(savedhot.sb, this);
                        obj3.showbox = showbox;
                    } else {
                        let showbox = new _showbox;
                        obj3.showbox = showbox;
                    }
                }
            })
        }
    }

    apply_mode_bind = (model, depth, binds) => {
        let b;
        for (let i = 0; i < binds.length; i++) {
            let bind = binds[i];
            if (bind.depth == depth) {
                b = bind;
                break;
            }
        }
        if (b) {
            if (b.bind_id) {
                let res = this.user_item_info[b.bind_id];
                if (res && model.material) {
                    model.bind_id = b.bind_id;
                    model.material.map = this.o2.create_map(res.preview);
                }

                if (b.sb) {
                    let showbox = new _showbox;
                    showbox.from_json(b.sb, this);
                    model.showbox = showbox;
                } else {
                    let showbox = new _showbox;
                    model.showbox = showbox;
                    if (item.type == "image") {
                        showbox.bPopUp = true;
                    }
                    let res = this.user_item_info[b.bind_id];
                    if (res) showbox.res_list.push(res);
                }
            }
        }
        for (let i = 0; i < model.children.length; i++) {
            let depth2 = depth + "_" + i;
            this.apply_mode_bind(model.children[i], depth2, binds);
        }
    }
    load_models = () => {
        console.log("load_models");
        let sr = this;
        let showroom=this;
        sr.models = [];
        if (!this.room.user_model) return;
        for (let i = 0; i < this.room.user_model.length; i++) {
            let um = this.room.user_model[i];
            let res = sr.user_item_info[um.resid.toString()];
            if (res&&res.name) {

                let ext = res.name.substr(-3, 3);
                console.log(ext);
                ext = ext.toLowerCase();
                if (ext=="fbx")//FBX装载
                {
                    // model
                    const loader = new FBXLoader();
                    loader.load( res.url, function ( object ) {
                        console.log(object);
                        let obj3=object;

                        
                        obj3.user_model = true;
                        obj3.item = res;
                        sr.models.push(obj3);
                        sr.o2.scene.add(obj3);
                        let mat = um.wts;
                        obj3.position.set(0, 0, 0);
                        obj3.scale.set(1, 1, 1);
                        obj3.rotation.set(0, 0, 0);
                        obj3.applyMatrix4(mat);
                        sr.set_fbx_mtl(obj3);
                    } );
                }else{
                    let promise = this.o2.import_object_url(res.url);
                    promise.then((obj3) => {
                        if (obj3) {
                            obj3.user_model = true;
                            obj3.item = res;
                            sr.o2.renew_all_uuid(obj3);
                            sr.models.push(obj3);
                            sr.o2.scene.add(obj3);
                            let mat = um.wts;
                            obj3.position.set(0, 0, 0);
                            obj3.scale.set(1, 1, 1);
                            obj3.rotation.set(0, 0, 0);
                            obj3.applyMatrix4(mat);
                            this.apply_mode_bind(obj3, "", um.binds);
                        }
                    })
                }
            }
        }
    }
    set_gizmo_mode = (mode) => {
        if (!this.gizmo) return;
        let oldmode = this.gizmo_mode;
        if (this.gizmo_mode == mode) return;
        this.gizmo_mode = mode;
        // if (oldmode && oldmode != 0 && this.gizmo_mode == 0) {
        //     this.o2.scene.remove(this.gizmo);
        //     return;
        // }
        if (!oldmode && this.gizmo_mode != 0) {
            this.o2.scene.add(this.gizmo);
        }
        if (mode == 1) {
            this.gizmo.setMode('translate');
        }
        if (mode == 2) {
            this.gizmo.setMode('rotate');
        }
        if (mode == 3) {
            this.gizmo.setMode('scale');
        }

    }
    remove_hot = (model) => {
        if (model.bHot_area) {
            if (this.hot_area_parent) {
                this.hot_area_parent.remove(model);
                let newlst = [];
                for (let i = 0; i < this.hot_areas.length; i++) {
                    if (this.hot_areas[i] != model) {
                        newlst.push(this.hot_areas[i]);
                    }
                }
                this.hot_areas = newlst;
            }
            if (model == this.selected_model) {
                this.set_gizmo_object(null);
            }
            return;
        }
        console.log(model)
        let left_list = [];
        for (let i = 0; i < this.hots.length; i++) {
            let mdl = this.hots[i];
            if (mdl != model) {
                left_list.push(mdl);
            }
        }
        this.hots = left_list;
        this.o2.scene.remove(model);
        if (model == this.selected_model) {
            this.set_gizmo_object(null);
        }
    }
    remove_model = (model) => {
        let left_list = [];
        for (let i = 0; i < this.models.length; i++) {
            let mdl = this.models[i];
            if (mdl != model) {
                left_list.push(mdl);
            }
        }
        this.models = left_list;
        this.o2.scene.remove(model);
        if (model == this.selected_model) {
            this.set_gizmo_object(null);
        }
    }

    set_gizmo_object = (obj) => {

        if (!this.gizmo) {
            this.gizmo = new TransformControls(this.o2.camera, this.o2.renderer.domElement);
            this.gizmo.ctrl = this.o2.cameraControls;
            this.gizmo.addEventListener('dragging-changed', function (event) {
                this.ctrl.enabled = !event.value;
                if(document.showroom.bCopyModel){
                    document.showroom.bCopyModel = false;
                    let curObj = document.showroom.selected_model;
                    let item = curObj.item;
                    if(!item)return;
                    let promise = document.o2.import_object_url(item.url);
                    promise.then((obj3) => {
                        if (obj3) {
                            obj3.user_model = true;
                            obj3.item = item;
                            document.showroom.o2.renew_all_uuid(obj3);
                            document.showroom.models.push(obj3);
                            document.showroom.o2.scene.add(obj3);
                            obj3.position.set(curObj.position.x,curObj.position.y,curObj.position.z);
                            obj3.rotation.set(curObj.rotation.x,curObj.rotation.y,curObj.rotation.z);
                            obj3.scale.set(curObj.scale.x,curObj.scale.y,curObj.scale.z);
                        }
                    })
                }
            });
        }

        this.selected_model = obj;
        if (obj) {
            this.gizmo.attach(obj);
            this.gizmo.setSpace("local");
        } else {
            this.gizmo.detach();
            this.gizmo.setSpace("world");
        }
    }

    add_exhibition_item = (object, item) => {
        //console.log(object);
        let map = this.o2.create_map(item.preview);
        object.material.map = map;
        let oldbind_id = object.bind_id;
        object.bind_id = item.id;
        object.bChangeMap = true;
        let showbox;
        if (object.showbox) {//已经有了
            showbox = object.showbox;
            if (showbox.res_list.length == 1)//只有一个的时候再替换
            {
                if (showbox.res_list[0].id == oldbind_id)//旧资源和旧展项一致
                {
                    showbox.res_list = [];
                    showbox.res_list.push(item);
                }
            }
        } else {//新画板
            showbox = new _showbox;
            object.showbox = showbox;
            if (item.type == "image") {
                showbox.bPopUp = true;
                showbox.linkFlag = 1;
            }
            showbox.res_list.push(item);
        }

        //判断素材是不是用户自己布置的
        let user_object;
        let obj2 = object;
        while (obj2 && obj2 != this.o2.scene) {
            if (obj2.user_model == true) {
                user_object = obj2;
                break;
            }
            obj2 = obj2.parent;
        }
        if (user_object)//用户自定义展项
        {
            user_object.bind = item;
            return;
        }
        if (!this.room.bind) this.room.bind = [];
        let bind = this.get_object_bind(object);
        if (bind) {
            bind.resid = item.id;
        }
        else {
            bind = {};
            bind.uuid = object.uuid;
            bind.resid = item.id;
            this.room.bind.push(bind);
            if (!this.user_item_info) {
                this.user_item_info = {};
            }
            if (!this.user_item_info[item.id.toString()])//插入到userinfo
            {
                let info = {};
                info.id = item.id;
                info.name = item.name;
                info.preview = item.preview;
                info.url = item.url;
                this.user_item_info[item.id.toString()] = info;
            }
        }
    }
    load_lut = () =>
    {
        if (this.room.lutName)
        {
            let name = this.room.lutName;
            this.switch_lut(name);
        }
    }
    load_camera_record = () => {
        //camera
        if (this.room.camera_record && this._load_camera_record) {
            this._load_camera_record(this.room.camera_record);
        }
    }
    load(params, template) {
        this.room = params
        console.log("开始加载", this.room);
        this.getUrl()

        if (this.room.viewHeight) {
            Bus.$emit("viewHeight", this.room.viewHeight)
        }

        if (!this.room.moveSpd) {
            this.room.moveSpd = 5
        }

        Bus.$emit("moveSpd", this.room.moveSpd * 400 + 2000)
        if (!this.room.view && template && template.view) {
            this.room.view = template.view
        }

        if (document.showroom.room.logo) {

        } else {
            console.log(document.showroom.room);
            document.showroom.room.logo = {
                preview: require("../../preview/assets/img/logo.png"),
            };
        }
        

        // console.log(this.getUrl, 321321)
        let container = document.getElementById("container");
        this.SCREEN_WIDTH = container.clientWidth;
        this.SCREEN_HEIGHT = container.clientHeight;
        var _o2 = document.o2vr;
        _o2.init(container);
        let ppp = _o2.load_zip_scene(params.url);
        var that = this

        {
            _o2.script = {};
            let scripts = _o2.script;
            _o2.dist_appear = false;
            scripts["init"] = function init() {
                that.bPrepare = false;
                console.log("inited");
                _o2.on_loading_msg("正在下载:", String(Math.ceil(100)), "%");
                if (that.room.view) {
                    console.log(that.room.view)
                    var view = that.room.view.split(",");
                    _o2.cameraControls.target.set(
                        view[0],
                        view[1],
                        view[2]
                    );
                    _o2.camera.position.set(
                        view[3],
                        view[4],
                        view[5]
                    );
                }
                _o2.cameraControls.panSpeed = 0.5;

                // _o2.cameraControls.minDistance = 0.1;
                // _o2.cameraControls.maxDistance = 15000;
                // if (params.maxDistance) {
                //     this.cameraMaxDistance = params.maxDistance;
                // }
                // if (params.minDistance) {
                //     this.cameraMinDistance = params.minDistance;
                // }



                _o2.cameraControls.maxPolarAngle = Math.PI - Math.PI / 10;
                _o2.cameraControls.minPolarAngle = Math.PI / 10;
                that.user_item_info = that.room.user_item_info;
                that.room.user_item_info = null;

                if (_o2.init_play) _o2.init_play();
                that.load_models();
                that.bind_items();
                that.load_camera_record();
                that.load_hots();
                that.load_hot_areas();
                that.prepare_run();
                that.load_lut();
            };
        }

        ppp.then(() => {
            console.log("load ended");
            document.o2.camera.fov = this.room.fov?this.room.fov:60;
            document.o2.camera.updateProjectionMatrix();

        });
    }

    save() {
        return this.room
    }


    //播放音频
    playAudio(e) {
        var audio = new Audio()
        audio.autoplay = true
        audio.id = 'audio'
        audio.src = e
        document.body.appendChild(audio)
    }

    //移除音频
    removeAudio() {
        document.body.removeChild(document.getElementById("audio"))
    }

    set_camera_controls(bEdit) {
        let _o2 = document.o2;
        if (_o2) {
            if (!bEdit) {
                if (this.room.maxDistance) {
                    _o2.cameraControls.maxDistance = this.room.maxDistance;
                } else {
                    _o2.cameraControls.maxDistance = 20000;
                }
                if (this.room.minDistance) {
                    _o2.cameraControls.minDistance = this.room.minDistance;
                } else {
                    _o2.cameraControls.minDistance = 0.1;
                }
            }
            else {
                _o2.cameraControls.minDistance = 0.1;
                _o2.cameraControls.maxDistance = Number.POSITIVE_INFINITY;
            }
        }
    }
    encode64(input) {

        var keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
        var output = "";
        var chr1, chr2, chr3 = "";
        var enc1, enc2, enc3, enc4 = "";
        var i = 0;
        do {
            chr1 = input.charCodeAt(i++);
            chr2 = input.charCodeAt(i++);
            chr3 = input.charCodeAt(i++);
            enc1 = chr1 >> 2;
            enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
            enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
            enc4 = chr3 & 63;
            if (isNaN(chr2)) {
                enc3 = enc4 = 64;
            } else if (isNaN(chr3)) {
                enc4 = 64;
            }
            output = output + keyStr.charAt(enc1) + keyStr.charAt(enc2)
                + keyStr.charAt(enc3) + keyStr.charAt(enc4);
            chr1 = chr2 = chr3 = "";
            enc1 = enc2 = enc3 = enc4 = "";
        } while (i < input.length);
        return output;
    }




    //将Base64编码字符串转换成Ansi编码的字符串
    decode64(input) {
        var keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
        var output = "";
        var chr1, chr2, chr3 = "";
        var enc1, enc2, enc3, enc4 = "";
        var i = 0;
        if (input.length % 4 != 0) {
            return "";
        }
        var base64test = /[^A-Za-z0-9\+\/\=]/g;
        if (base64test.exec(input)) {
            return "";
        }
        do {
            enc1 = keyStr.indexOf(input.charAt(i++));
            enc2 = keyStr.indexOf(input.charAt(i++));
            enc3 = keyStr.indexOf(input.charAt(i++));
            enc4 = keyStr.indexOf(input.charAt(i++));
            chr1 = (enc1 << 2) | (enc2 >> 4);
            chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
            chr3 = ((enc3 & 3) << 6) | enc4;
            output = output + String.fromCharCode(chr1);
            if (enc3 != 64) {
                output += String.fromCharCode(chr2);
            }
            if (enc4 != 64) {
                output += String.fromCharCode(chr3);
            }
            chr1 = chr2 = chr3 = "";
            enc1 = enc2 = enc3 = enc4 = "";
        } while (i < input.length);
        return output;
    }
    utf16to8(str) {
        var keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
        var out, i, len, c;
        out = "";
        len = str.length;
        for (i = 0; i < len; i++) {
            c = str.charCodeAt(i);
            if ((c >= 0x0001) && (c <= 0x007F)) {
                out += str.charAt(i);
            } else if (c > 0x07FF) {
                out += String.fromCharCode(0xE0 | ((c >> 12) & 0x0F));
                out += String.fromCharCode(0x80 | ((c >> 6) & 0x3F));
                out += String.fromCharCode(0x80 | ((c >> 0) & 0x3F));
            } else {
                out += String.fromCharCode(0xC0 | ((c >> 6) & 0x1F));
                out += String.fromCharCode(0x80 | ((c >> 0) & 0x3F));
            }
        }
        return out;
    }
    utf8to16(str) {
        var keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
        var out, i, len, c;
        var char2, char3;
        out = "";
        len = str.length;
        i = 0;
        while (i < len) {
            c = str.charCodeAt(i++);
            switch (c >> 4) {
                case 0: case 1: case 2: case 3: case 4: case 5: case 6: case 7:
                    // 0xxxxxxx
                    out += str.charAt(i - 1);
                    break;
                case 12: case 13:
                    // 110x xxxx 10xx xxxx
                    char2 = str.charCodeAt(i++);
                    out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
                    break;
                case 14:
                    // 1110 xxxx 10xx xxxx 10xx xxxx
                    char2 = str.charCodeAt(i++);
                    char3 = str.charCodeAt(i++);
                    out += String.fromCharCode(((c & 0x0F) << 12) |
                        ((char2 & 0x3F) << 6) |
                        ((char3 & 0x3F) << 0));
                    break;
            }
        }
        return out;
    }
}

export { showroom };