<template>
  <div :class="chatFlag ? 'chatRoom' : 'chatRoom closeRoom'">
    <h1>
      <div class="roomList" :class="chatFlag ? 'roomList' : ' roomList roomListClose'">
        <p
          v-for="(item, index) in cheatList"
          :key="index"
          :class="index == value ? 'active' : ''"
          @click="chooseRoom(index)"
        >
          {{ item.name }}
        </p>
      </div>
      <div class="function">
        <img
          class="icon"
          src="../../dongsheng/assets/newImg/chatRoom/close.png"
          @click="chatFlag = !chatFlag"
        />
        <img
          class="icon"
          src="../../dongsheng/assets/newImg/chatRoom/group.png"
          v-if="chatFlag"
          @click="groupFlag = !groupFlag"
        />
      </div>
    </h1>
    <div class="main">
      <div class="chat" id="chat">
        <div class="msgList" v-for="(item, index) in msgList[value]" :key="index">
          <div
            class="name"
            :style="item.name == $chat.role.name ? 'color: #1dd3b0;' : 'color:orange'"
          >
            {{ item.name }}:
          </div>
          <div class="sendMsg">{{ item.msg }}</div>
        </div>
      </div>

      <div class="group" v-if="groupFlag">
        <h3>
          <span style="color: #1dd3b0">{{ playerList.length }}</span>
          在线
        </h3>
        <div v-for="(item, index) in playerList" :key="index" class="msg">
          <div class="circle"></div>
          <p>{{ item.name }}</p>
          <div style="flex: 1"></div>
          <img
            src="../../dongsheng/assets/newImg/chatRoom/chat.png"
            class="logo"
            v-if="item.name != $chat.role.name"
            @click="onlyChat(item.name, item.name)"
          />
        </div>
      </div>
    </div>
    <div class="send">
      <el-input
        :placeholder="'请输入内容'"
        v-model="input"
        clearable
        @focus="keyFlag(true)"
        @blur="keyFlag(false)"
        ref="input"
      >
      </el-input>
      <el-button type="success" @click="send">发送</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cheatList: [{ name: "世界", id: 0 }],
      playerList: [],
      value: 0,
      input: "",
      groupFlag: true,
      chatFlag: true,
      msgList: [[]],
      name: "",
    };
  },
  methods: {
    chooseRoom(e) {
      this.value = e;
    },
    keyFlag(e) {
      console.log(123);
      this.Bus.$emit("keyFlag", e);
    },
    send() {
      console.log(123);
      if (!this.input) {
        this.$message.error("发送失败");
        return;
      }
      if (this.value == 0) {
        var e = `chats,${this.$chat.role.name},${this.input}`;
        this.$chat.send(e);
      } else {
        var e = `chat,${this.$chat.role.name},${this.cheatList[this.value].name},${
          this.input
        },${this.value}`;
        this.$chat.send(e);
      }
      this.input = "";
    },
    onlyChat(a, b) {
      console.log(a, b);
      if (this.$chat.role.name == b) {
        console.log("不能与自己聊天");
        return;
      }
      var found = this.cheatList.filter((el) => el.name === b).length > 0; // .length returns 1 if id exist in an array

      if (!found) {
        this.cheatList.push({
          name: a,
        });
      }
      var value = this.cheatList.findIndex((item) => item.name === b);
      this.value = value;
    },

    to_footer() {
      var div = document.getElementById("chat");
      setTimeout(() => {
        div.scrollTop = div.scrollHeight;
      }, 100);
    },
  },
  created() {
    var that = this;
    document.onkeydown = function (el) {
      if (el.keyCode == 13) {
        if (that.chatFlag) {
          that.send();
          that.$refs.input.blur();
        }
      } else {
      }
    };

    this.Bus.$on("msgList", (e) => {
      this.msgList = e;
      this.to_footer();
    });

    this.Bus.$on("player", (e) => {
      this.playerList = e.players;
    });

    this.Bus.$on("reciveMsg", (e) => {
      this.name = this.$chat.role.name;
      var found = this.cheatList.filter((el) => el.name === e.from).length > 0;
      if (!found) {
        this.msgList.push([]);
        this.cheatList.push({
          name: e.name,
          id: e.from,
        });
      }
      var value = this.cheatList.findIndex((item) => item.name === e.from);
      this.value = value;
      this.msgList[value].push({
        name: e.name,
        msg: e.msg,
      });
      this.$chat.msgList = this.msgList;
      this.to_footer();
    });
  },
};
</script>

<style scoped lang="scss">
.chatRoom {
  width: 448px;
  height: 245px;
  background: rgba($color: #545c64, $alpha: 0.7);
  border-radius: 4px;
  position: fixed;
  left: 20px;
  bottom: 0;
  z-index: 999;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  transition: 0.5s;
  h1 {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .roomList {
      width: 350px;
      display: flex;
      align-items: center;
    }
    .roomListClose {
      width: 160px;
      overflow: hidden;
    }
    p {
      width: 50px;
      height: 24px;
      background: #6a6a6a;
      border-radius: 2px;
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
      text-align: center;
      color: #afafaf;
      margin-left: 8px;
      cursor: pointer;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
      padding: 0 5px;
    }
    .active {
      color: #fff;
      background: #1dd3b0;
      transition: 0.5s;
    }
    .icon {
      width: 24px;
      height: 24px;
      float: right;
      display: inline-block;
      margin-right: 10px;
      cursor: pointer;
    }
  }
  .main {
    height: 150px;
    display: flex;
    margin-bottom: 10px;
    .chat {
      overflow-y: scroll;
      width: 100%;
      flex: 1;
      .msgList {
        display: flex;
        flex-wrap: wrap;
        font-size: 14px;
        margin-bottom: 8px;
        .name {
          margin: 0 12px;

          width: 70px;
          text-align: left;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
        }
        .sendMsg {
          color: #fff;
          flex: 1;
          text-align: left;
          margin-right: 12px;
          word-wrap: break-word;
          width: 180px;
          // display: flex;
          // align-items: stretch;
        }
      }
    }
    .chat::-webkit-scrollbar,
    .group::-webkit-scrollbar {
      width: 4px;
      opacity: 0.6;
    }
    .chat::-webkit-scrollbar-track,
    .group::-webkit-scrollbar-track {
      background: #ffffff;
      -webkit-border-radius: 2em;
      -moz-border-radius: 2em;
      border-radius: 2em;
    }

    .chat::-webkit-scrollbar-thumb,
    .group::-webkit-scrollbar-thumb {
      background-color: #1dd3b0;
      -webkit-border-radius: 2em;
      -moz-border-radius: 2em;
      border-radius: 2em;
    }

    .group {
      width: 170px;
      overflow-y: scroll;
      h3 {
        font-size: 12px;
        color: #fff;
        text-align: center;
        margin-bottom: 6px;
      }
      .msg {
        display: flex;
        width: 155px;
        height: 28px;

        background: rgba($color: #fff, $alpha: 0.23);
        border-radius: 2px;
        margin-bottom: 6px;
        margin-left: 5px;
        align-items: center;
        .circle {
          width: 6px;
          height: 6px;
          background: #3dffb1;

          border-radius: 50%;
          margin-left: 5px;
        }
        p {
          font-size: 13px;
          color: #ffffff;
          margin-left: 5px;
          width: 100px;
          text-align: left;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
        }
        .logo {
          margin-right: 5px;
          cursor: pointer;
          width: 20px;
        }
      }
    }
  }
  .send {
    display: flex;
    margin: 0 10px;
    .el-button--success {
      background: #1dd3b0;
      margin-left: 10px;
    }
  }
}
.closeRoom {
  width: 200px;
  transition: 0.5s;
  transform: translateY(207px);
}
</style>
