var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('transition',{attrs:{"enter-active-class":"animated fadeIn","leave-active-class":"animated fadeOut"}},[(_vm.flag)?_c('div',{staticClass:"box",attrs:{"id":"box"}},[_c('div',[(_vm.list.length > 0 && _vm.checkFlag(_vm.list[_vm.value]) && _vm.imgFlag)?_c('img',{staticClass:"img1",style:('transform:' +
            'scale(' +
            _vm.scale +
            ');left:' +
            _vm.position.left +
            'px;' +
            'right:' +
            _vm.position.right +
            'px;' +
            'top:' +
            _vm.position.top +
            'px;' +
            'bottom:' +
            _vm.position.bottom +
            'px;'),attrs:{"src":_vm.list[_vm.value].url ? _vm.list[_vm.value].url : '',"id":"img"},on:{"click":function($event){$event.stopPropagation();},"load":_vm.checkWidth}}):_vm._e(),(_vm.list.length > 0 && _vm.checkFlag(_vm.list[_vm.value]) && _vm.imgFlag)?_c('div',{staticClass:"img1",style:('transform:' +
            'scale(' +
            _vm.scale +
            ');left:' +
            _vm.position.left +
            'px;' +
            'right:' +
            _vm.position.right +
            'px;' +
            'top:' +
            _vm.position.top +
            'px;' +
            'bottom:' +
            _vm.position.bottom +
            'px;'),on:{"mousedown":function($event){$event.stopPropagation();return _vm.start.apply(null, arguments)},"touchstart":function($event){$event.stopPropagation();return _vm.start.apply(null, arguments)}}},[_c('audio',{attrs:{"autoplay":"","src":_vm.audio}})]):_vm._e(),(_vm.list.length > 0 && !_vm.checkFlag(_vm.list[_vm.value]) && !_vm.iframeFlag)?_c('video-player',{ref:"videoPlayer",staticClass:"video-player vjs-custom-skin video",attrs:{"playsinline":true,"options":_vm.playerOptions}}):_vm._e()],1),(_vm.list.length > 0 && _vm.checkFlag(_vm.list[_vm.value]) && _vm.imgFlag)?_c('div',{staticClass:"function"},_vm._l((_vm.functionBox),function(item,index){return _c('div',{key:index,on:{"click":function($event){$event.stopPropagation();return _vm.f(index)}}},[_c('img',{attrs:{"src":item.img}}),_c('p',[_vm._v(_vm._s(item.font))])])}),0):_vm._e(),_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../assets/img/close.png")},on:{"click":_vm.close}})]),(_vm.title)?_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.title))]):_vm._e(),(_vm.list.length > 0)?_c('div',{staticClass:"title title1"},[_c('p',[_vm._v(_vm._s(_vm.value + 1)+"/"+_vm._s(_vm.list.length))])]):_vm._e(),(_vm.text)?_c('div',{staticClass:"text",on:{"click":function($event){$event.stopPropagation();}}},[_c('p',[_vm._v(_vm._s(_vm.text))])]):_vm._e(),(_vm.value > 0 && _vm.list.length > 0)?_c('img',{staticClass:"left",attrs:{"src":require("../assets/img/bg-left.png")},on:{"click":function($event){_vm.value--}}}):_vm._e(),(_vm.value < _vm.list.length - 1 && _vm.list.length > 0)?_c('img',{staticClass:"right",attrs:{"src":require("../assets/img/bg-right.png")},on:{"click":function($event){_vm.value++}}}):_vm._e(),(_vm.iframeFlag)?_c('iframe',{staticStyle:{"width":"80%","height":"80%","margin":"5% 10%"},attrs:{"src":_vm.link,"frameborder":"0"}}):_vm._e()]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }