<template>
  <div>
    <div class="loading" ref="load" v-if="obj">
      <div class="loading1" v-if="obj.banner">
        <img :src="obj.banner" class="banner" />
        <div class="shadow"></div>
      </div>

      <div class="loading1" v-else></div>
      <div>
        <div class="line" v-if="vflag">
          <div class="full" :style="'width:' + width + '%'"></div>
          <div class="loadmsg">{{ loading_msg }}</div>
        </div>
        <div class="btn" v-else @click="close">点击进入场景</div>
      </div>

      <img :src="obj.logo.preview" class="logo" v-if="obj.logo" />
      <!-- <img src="../assets/img/logo.png" v-else class="logo" /> -->

      <div class="title">{{ sceneTitle }}</div>

      <!-- <div class="bottom1">商迪3D提供技术支持</div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: ["obj"],
  data() {
    return {
      //喷漆颜色列表
      loading_msg: "",
      width: 0,
      vflag: true,
      sceneTitle: "",
    };
  },
  methods: {
    close() {
      setTimeout(() => {
        this.$refs.load.style.opacity = 0;
      }, 100);
      setTimeout(() => {
        this.$refs.load.style.display = "none";
      }, 500);
    },
  },

  created() {
    document.o2vr.on_loading_msg = (a, b, c) => {
      console.log(a, b, c);
      this.loading_msg = `${b}${c}`;
      this.width = b;
      if (b == 100) {
        this.vflag = false;
        //   setTimeout(() => {
        //     this.$refs.load.style.opacity = 0;
        //   }, 1000);
        //   setTimeout(() => {
        //     this.$refs.load.style.display = "none";
        //   }, 2000);
      }
    };
  },
  mounted() {
    // console.log(this.obj);
  },

  watch: {
    obj(val) {
      console.log(val);

      this.sceneTitle = document.showroom.utf8to16(
        document.showroom.decode64(val.sceneTitle)
      );
    },
  },
};
</script>

<style scoped>
.btn {
  width: 156px;
  height: 40px;
  background: linear-gradient(90deg, #3366ff 0%, #3399fe 100%);
  opacity: 1;
  border-radius: 20px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  cursor: pointer;
}
.title {
  font-size: 25px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 51px;
  color: #ffffff;
  opacity: 1;
  top: 40%;
  position: fixed;
  left: 0;
  right: 0;
  margin: auto;
}
.banner {
  left: 0;
  top: 0;
  position: fixed;
  width: 100%;
  height: 100%;
}
.shadow {
  background-color: black;
  opacity: 0.7;
  left: 0;
  top: 0;
  position: fixed;
  width: 100%;
  height: 100%;
}
.bottom1 {
  font-size: 22px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 37px;
  color: #ffffff;
  opacity: 0.65;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 30px;
  position: fixed;
}
.loadmsg {
  position: absolute;
  left: 310px;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 30px;
  color: #ffffff;
  opacity: 0.65;
  top: -11px;
}
.logo {
  width: 100px;
  height: 100px;
  position: absolute;
  left: 0;
  right: 0;
  top: 25%;
  margin: auto;
  border-radius: 50%;
}
.loading1 {
  position: absolute;
  width: 100%;

  height: 100%;
  background-color: rgb(20, 35, 52);
  top: 0;
  left: 0;
}
.loading {
  position: absolute;
  width: 100%;
  color: white;
  height: 100%;
  /* background-color: rgb(20, 35, 52); */
  position: absolute;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 101;
  font-size: 30px;
  opacity: 1;
  transition: 1s;
  background-size: 100% 100%;
  overflow: hidden;
}
.color {
  color: #21ebff;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1000;
}
.line {
  display: inline-block;
  margin-top: 30px;
  width: 300px;
  height: 10px;
  background: rgba(255, 255, 255, 0.39);
  border-radius: 5px;
  position: relative;
}

.full {
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  background: white;
  transition: 0.5;
  height: 100%;
  background: linear-gradient(90deg, #3366ff 0%, #3399fe 100%);
}

@media only screen and (min-width: 500px) and (max-width: 1000px) {
  .title {
    top: 52%;
  }
  .btn {
    top: 70%;
  }
  .line {
    top: 80px;
  }
}
</style>
