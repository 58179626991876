<template>
  <div>
    <transition
      enter-active-class="animated fadeInRight"
      leave-active-class="animated fadeOutRight"
    >
      <div
        class="shadow"
        :class="suo ? 'tran' : ''"
        v-show="localFlag || list.length > 0"
        id="shadow"
      >
        <img
          src="../../wdf/assets/右箭头.png"
          class="arrow"
          :class="suo ? 'arrow-ani' : ''"
          @click="suo = !suo"
        />
        <div id="restream"></div>
        <transition
          enter-active-class="animated fadeIn"
          leave-active-class="animated fadeOut"
        >
          <div
            id="shareScreen"
            :class="playValue == -2 ? 'videoBox1 pos' : 'videoBox1'"
            v-if="shareFlag"
            @click="playValue = -2"
          >
            <img
              src="../../wdf/assets/newImg/function/close.png"
              class="close"
              v-if="playValue == -2"
              @click.stop="playValue = 999"
            />
            <div class="msg">
              <p>{{ sharename }}正在分享屏幕</p>

              <!-- <img src="../../wdf/assets/newImg/function/qp.png" @click="qpFlag = !qpFlag" /> -->
            </div>
          </div>
        </transition>
        <div
          :class="playValue == -1 ? 'videoBox pos' : 'videoBox'"
          @click="playValue = -1"
          v-show="localFlag"
        >
          <!-- <img src="../../wdf/assets/newImg/function/background.png" class="img" /> -->
          <img
            src="../../wdf/assets/newImg/function/close.png"
            class="close"
            v-if="playValue == -1"
            @click.stop="playValue = 999"
          />

          <div
            id="local_stream"
            autoplay
            style="width: 100%; height: 100%; position: absolute; top: 0; z-index: 99"
          >
            <div v-show="!shipinFlag" class="shipin">
              {{ $chat.role.name }}
            </div>
          </div>
          <div class="msg">
            <p>{{ $chat.role.name }}</p>
            <div class="iconList">
              <img
                :src="
                  yuyinFlag
                    ? require('../../wdf/assets/newImg/function/yuyinOpen.png')
                    : require('../../wdf/assets/newImg/function/yuyinClose.png')
                "
              />
              <img
                :src="
                  shipinFlag
                    ? require('../../wdf/assets/newImg/function/xiangjiOpen.png')
                    : require('../../wdf/assets/newImg/function/xiangjiClose.png')
                "
              />
            </div>
          </div>
        </div>
        <div class="msgList" style="position: absolute">
          <div
            class="videoBox"
            v-for="(item, index) in list"
            :key="index"
            @click="playValue = index"
          >
            <img
              src="../../wdf/assets/newImg/function/close.png"
              class="close"
              v-if="playValue == index"
              @click.stop="playValue = 999"
            />
            <!-- <div
            :id="item.userId"
            autoplay
            style="width: 100%; height: 100%; position: absolute; top: 0; z-index: 99"
          ></div> -->
            <div class="msg">
              <p>
                {{ item.userId }}
              </p>
              <div class="iconList">
                <img
                  v-if="!item.hasAudio"
                  src="../../wdf/assets/newImg/function/yuyinClose.png"
                />
                <img
                  v-else
                  :src="
                    !item.audioMuted
                      ? require('../../wdf/assets/newImg/function/yuyinOpen.png')
                      : require('../../wdf/assets/newImg/function/yuyinClose.png')
                  "
                />

                <img
                  src="../../wdf/assets/newImg/function/xiangjiClose.png"
                  v-if="!item.hasVideo"
                />
                <img
                  v-else
                  :src="
                    !item.videoMuted
                      ? require('../../wdf/assets/newImg/function/xiangjiOpen.png')
                      : require('../../wdf/assets/newImg/function/xiangjiClose.png')
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { MessagePlugin } from 'tdesign-vue'
export default {
  data() {
    return {
      list: [],
      yuyinFlag: false,
      shipinFlag: false,
      screenFlag: false,
      playValue: 999,
      shareFlag: false,
      sharename: '',
      qpFlag: false,
      localFlag: false,
      imgFlag: false,
      two: false,
      jinyanflag: false,

      hostguest: false,
      shrinkFlag: false,
      suo: false
    }
  },
  mounted() {
    this.Bus.$on('spliu', (e) => {
      console.log(e)
    })

    this.Bus.$on('mute', (e) => {
      if (!e) {
        MessagePlugin.error({
          placement: 'top-right',
          content: '您的语音已关闭',
          duration: 1000
        })
        this.yuyinFlag = false
      } else {
        MessagePlugin.success({
          placement: 'top-right',
          content: '您的语音已开启',
          duration: 1000
        })
        this.yuyinFlag = true
      }
    })
    this.Bus.$on('vmute', (e) => {
      if (!e[0]) {
        MessagePlugin.error({
          placement: 'top-right',
          content: '您的视频已关闭',
          duration: 1000
        })
        this.shipinFlag = false
      } else {
        MessagePlugin.success({
          placement: 'top-right',
          content: '您的视频已开启',
          duration: 1000
        })
        this.shipinFlag = true
      }
    })
  },
  methods: {},
  created() {
    this.Bus.$on('clientOut', (e) => {
      console.log('退出房间')
      document.getElementById('local_stream').innerHTML = ''
      this.list = []
      this.$client.loginOut()
      MessagePlugin.success({
        placement: 'top-right',
        content: '您已退出语音区间',
        duration: 1000
      })
      this.$client.clientFlag = false
      this.shareFlag = false
      this.yuyinFlag = false
      this.shipinFlag = false
      this.screenFlag = false
      this.imgFlag = false
    })
    this.Bus.$on('localFlag', (e) => {
      this.localFlag = e
      console.log(this.localFlag)
    })
    this.Bus.$on('listChange', (e) => {
      if (e.length > 0) {
        // this.list = e;
        e.forEach((stream) => {
          this.list.find(function(value) {
            if (value.userId == stream.userId) {
              value.audioMuted = stream.audioMuted
              value.hasAudio = stream.hasAudio
              value.hasVideo = stream.hasVideo
              value.videoMuted = stream.videoMuted
            }
          })
        })
      }
    })
    this.Bus.$on('addlist', (e) => {
      this.list.push({
        userId: e.getUserId(),
        audioMuted: true,
        videoMuted: true,
        hasAudio: false,
        hasVideo: false
      })
      var name = e.getUserId()
      var node = document.createElement('div')
      node.setAttribute('id', name)
      node.setAttribute('class', 'videoBox1')
      var shadow = document.getElementById('shadow')
      shadow.appendChild(node)
      e.play(name)

      console.log(e)
    })
    this.Bus.$on('removelist', (e) => {
      console.log(e, 'remove')
      var box = document.getElementById(e.getUserId())
      box.remove()
      this.list = this.list.filter((stream) => {
        return stream.userId !== e.getUserId()
      })
    })

    //远方流列表
    this.Bus.$on('micError', () => {
      MessagePlugin.warning({
        placement: 'top',
        content: '检测到您没有麦克风,语音功能将失效',
        duration: 3000
      })
    })
    //摄像机报错
    this.Bus.$on('cameraError', () => {
      MessagePlugin.warning({
        placement: 'top',
        content: '检测到您没有摄像头,视频功能将失效',
        duration: 3000
      })
    })
    //屏幕分享报错

    this.Bus.$on('shareScreen', (e) => {
      this.shareFlag = true
      this.sharename = e.sharename
    })

    this.Bus.$on('shareClose', (e) => {
      this.shareFlag = false
      this.sharename = ''
      this.screenFlag = false
    })

    //屏幕分享

    this.Bus.$on('login', () => {
      MessagePlugin.success({
        placement: 'top-right',
        content: '您已加入语音区间',
        duration: 1000
      })

      this.$client.clientFlag = true
    })
  }
}
</script>

<style lang="scss" scoped>
.shadow {
  // width: 250px;
  // padding: 20px 25px;
  // position: absolute;
  // top: 0;
  // right: 74px;
  // height: calc(100% - 114px);
  // background: rgba($color: #11151e, $alpha: 0.5);
  // z-index: 9999;
  // overflow-y: scroll;
  // overflow-x: hidden;

  // width: 250px;
  // padding: 20px 25px;
  // position: absolute;
  // top: 236px;
  // right: 0px;
  // height: calc(100% - 114px - 236px);
  // background: rgba($color: #11151e, $alpha: 0.5);
  // z-index: 9999;
  // overflow-y: scroll;
  // overflow-x: hidden;

  width: 304px;
  padding: 20px 25px;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  background: rgba($color: #11151e, $alpha: 0.5);
  z-index: 99;
  overflow-y: scroll;
  overflow-x: hidden;
  transition: all 0.2s linear;

  .arrow {
    width: 25px;
    height: 30px;
    position: absolute;
    left: -2px;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.2s linear;
    cursor: pointer;
  }

  .arrow-ani {
    transform: translateY(-50%) rotate(180deg);
  }

  .videoBox {
    width: 250px;
    height: 150px;
    margin-bottom: 20px;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
  }

  .pos {
    position: fixed;
    left: 0;
    right: 0;
    margin: auto;
    height: 100% !important;
    top: 0px;
    width: 100% !important;
    transition: 0.5s;
    z-index: 9999;
  }
}

.tran {
  transform: translateX(336px);
}

.shrink {
  position: fixed;
  right: 300px;
  top: 50%;
  width: 20px;
  height: 80px;
  transform: translateY(-50%);
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background: rgba($color: #11151e, $alpha: 0.5);

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}

@keyframes shrg {
  0% {
    right: 300px;
  }
  20% {
    right: 240px;
  }
  40% {
    right: 180px;
  }
  60% {
    right: 120px;
  }
  80% {
    right: 60px;
  }
  100% {
    right: 0;
  }
}

.anishgo {
  animation: shrg forwards;
}

@keyframes shrb {
  0% {
    right: 0;
  }
  20% {
    right: 60px;
  }
  40% {
    right: 120px;
  }
  60% {
    right: 180px;
  }
  80% {
    right: 240px;
  }
  100% {
    right: 300px;
  }
}

.anishback {
  animation: shrb forwards;
}

.qp {
  width: 80%;
  height: 80%;
  z-index: 10001;
}

.msg {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 30px;
  background: rgba($color: #11151e, $alpha: 0.8);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1001;
  p {
    color: #fff;
    font-size: 12px;
    margin-left: 10px;
  }
  .iconList {
    display: flex;
    align-items: center;
  }

  img {
    margin-right: 10px;
    width: 20px;
    cursor: pointer;
  }
}
.img {
  width: 100%;
  height: 100%;
}
.close {
  position: absolute;
  right: 64px;
  top: 20px;
  z-index: 999;
}
.shadow::-webkit-scrollbar {
  width: 4px;
  opacity: 0.6;
}

.shadow::-webkit-scrollbar-track {
  background: #ffffff;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}

#restream {
  position: absolute;
  width: 250px;
  padding: 20px 25px;
  position: absolute;
  overflow-y: scroll;
}

.shipin {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 100;
  background: #246dff;

  display: flex;
  justify-content: center;
  align-items: center;

  color: #fff;
  font-size: 3em;
}

@media screen and (max-width: 1000px) {
  .cFunction {
    transform: scale(0.8);
    top: -6px;
  }

  .shadow {
    width: 212.8px;

    .arrow {
      width: 17.5px;
      height: 21px;
      left: 0;
    }
  }

  .videoBox {
    width: 175px !important;
    height: 105px !important;
    margin-bottom: 14px !important;
  }

  .tran {
    transform: translateX(245px);
  }

  .close {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 999;
  }
}
</style>
