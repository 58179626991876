<template>
  <div class="test"></div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.test {
  width: 100%;
  height: 100%;
  background: url('../assets/img/test.png');
  background-size: 100% 100%;
  position: absolute;
  z-index: 9999;
}
</style>
