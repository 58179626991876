import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    KeyboardFlag: "",
    edit_info:""
  },
  mutations: {
    changeFlag(state, flag) {
      state.KeyboardFlag = flag
    },
    //按钮权限
    getInfo(state, msg) {
      state.edit_info =msg
    },
    //获取edit——info
  },
  actions: {
  },
  modules: {
  },
  getters: {

  }
})
